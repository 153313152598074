import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { SocialBoxProps } from "../utils/types";
// import { formatDate, toNumber } from "../utils/common";
import intl from "../utils/intl";
import toast from "react-hot-toast";
import Tooltip from "./Tooltip";
import { writeContract, readContract, waitForTransaction } from "@wagmi/core";
import ConversationABI from '../abi/Conversation.json';
import { IMAGE_FILE_SIZE, IMAGE_PROXY, IPFS_GATEWAY } from "../config";
import useDeviceDetect from "../utils/useDeviceDetect";
import { NFTStorage } from "nft.storage";

const SocialBox: FC<SocialBoxProps> = ({
	factory,
	socialContractAddress,
	conversation,
	currentAddress,
	deployer
}) => {
	const isMobile = useDeviceDetect();
	const [editable, setEditable] = useState(false);

	const [name, setName] = useState("");
	const [logo, setLogo] = useState("");
	const [url, setUrl] = useState("");
	const [loading, setLoading] = useState(false);
	const [prefixUrls, setPrefixUrls] = useState(Array<string>);
	const [fileUploading, setFileUploading] = useState(false);
	const [ipfsImage, setIpfsImage] = useState("");

	const imageInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (!conversation.name) {
			setName("");
			setLogo("");
			setUrl("");
		} else {
			setName(conversation.name);
			setLogo(conversation.logo);
			setUrl(conversation.url);
		}
		setEditable(currentAddress === deployer);
	}, [conversation, currentAddress, deployer]);

	useEffect(() => {
		if (socialContractAddress === undefined || socialContractAddress === "") return;
		try {
			// console.log(socialContractAddress)
			readContract({
				address: socialContractAddress as any,
				abi: ConversationABI,
				functionName: "getPrefixUrls",
				args: []
			}).then((urls) => {
				setPrefixUrls(urls as any);
			})
		} catch (err) {

		}
	}, [socialContractAddress]);

	const uploadFile = async (file: File) => {
		try {
			const files = [file]
			if (files[0].size > IMAGE_FILE_SIZE) {
				toast.error(intl.get('exceed-file-size', { maxSize: IMAGE_FILE_SIZE / 1024 + "K" }));
				return;
			}

			setFileUploading(true);
			// const cid = await window.w3sClient.put(files, { name: 'logo' })
			// // console.log(cid);
			// setLogo(cid)

			const storage = new NFTStorage({ token: process.env.REACT_APP_NFT_STORAGE_KEY as string })

			const cid = await storage.store({
				name: 'Ferc20',
				description: 'Ferc20',
				image: file
			})
			if (cid && cid.data.image.href) {
				const regex = /ipfs:\/\/(.*?)\//
				const result = regex.exec(cid.data.image.href)

				if (result) setLogo(result[1])
				else toast.error("upload image fail")
			} else {
				toast.error("upload image fail")
			}

			setFileUploading(false);
		} catch (error) {
			console.log(error);
			setFileUploading(false);
		}
	};

	const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const file: File | undefined = e.target.files?.[0];
		if (!file) return;

		uploadFile(file);
	};

	const checkUrl = (urls: string) => {
		if (prefixUrls.length === 0) return false;
		else {
			for (let i = 0; i < prefixUrls.length; i++) {
				if (prefixUrls[i].toLowerCase() === urls.trim().substring(0, prefixUrls[i].length)) return true;
				else continue;
			}
			return false;
		}
	}
	const setLink = async () => {
		// console.log("socialContractAddress", socialContractAddress);
		// console.log("factory", factory);
		// console.log("token", conversation.addr);
		// console.log("pos", conversation.pos);
		// console.log("name", name);
		// console.log("logo", logo);
		// console.log("url", url);
		// console.log("tip", parseEther(tip));
		// console.log("new rate", rate === "" ? ethers.BN(0) : parseEther(rate));

		if (name === "" || logo === "" || url === "") {
			toast.error("Oops! You have input something wrong");
			return;
		}

		if (!checkUrl(url)) {
			toast.error("The url must start from one of " + prefixUrls);
			return;
		}

		if (logo.length >= 128) {
			toast.error("The logo url is too long");
			return
		}

		setLoading(true);

		try {
			const args = [
				factory,
				conversation.addr,
				conversation.pos,
				name,
				logo,
				url,
			];
			const { hash } = await writeContract({
				address: socialContractAddress as any,
				abi: ConversationABI,
				functionName: "setLink",
				args,
			});

			waitForTransaction({ hash }).then(async (data) => {
				if (data.status === 'success') {
					toast.success("You have set link in this position");
					setLoading(false);
				} else {
					toast.error("Error while setting link.");
					setLoading(false);
				}
			})
		} catch (err) {
			toast.error("Error: " + (err as any).shortMessage);
			// console.log((err as any).shortMessage);
			setLoading(false);
		}
	}

	useEffect(() => {
		const g = (async (cid: string) => {
			const res = await window.w3sClient.get(cid); // Web3Response
			const files = await res?.files(); // Web3File[]
			if (files && files.length > 0) {
				const file = files[0];
				setIpfsImage(`https://${cid}${IPFS_GATEWAY}/${file.name}`)
			} else {
				setIpfsImage(`https://${cid}${IPFS_GATEWAY}`)
			}
		})
		if (logo) {
			g(logo)
		}

	}, [logo])

	return (
		<>
			<div>
				<input type="checkbox" id="socialBoxModal" className="modal-toggle" />
				<label htmlFor="socialBoxModal" className="modal cursor-pointer">
					<label className="modal-box text-sm md:text-md" htmlFor="">
						<label htmlFor="socialBoxModal" className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</label>
						<h3 className="font-bold text-lg">{intl.get("conversation") + " of " + conversation.tick + ", slot #" + (conversation.pos + 1)}</h3>
						<div className="divider" />

						<div className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left">{intl.get("social-name")}
								{!isMobile && <Tooltip message="Name of your social network" />}
							</span>
							<input
								type="text"
								value={name}
								placeholder="Name of social network"
								className="input input-bordered w-[60%]"
								onChange={(e) => setName(e.target.value)}
							/>
						</div>

						<div className="input-group flex justify-center mb-3">
							<span className="min-w-[40%] text-left">{intl.get("social-logo")}
								{!isMobile && <Tooltip message="IPFS cid of your social network logo" />}
							</span>

							<input
								type="text"
								value={logo}
								placeholder="Logo of social network"
								className="input input-bordered w-full" readOnly
							/>
							{fileUploading ? <button className="btn btn-info btn-disabled loading"></button> : logo ?
								<div className="avatar rounded-r-full ml-1">
									<div className="w-12 h-12 rounded">
										<img src={IMAGE_PROXY(ipfsImage)} alt="" crossOrigin="anonymous" onClick={() => { if (editable) imageInputRef.current?.click() }} />
									</div>
								</div>
								: <button className="btn btn-secondary rounded-r-md" onClick={() => { if (editable) imageInputRef.current?.click() }}>{intl.get("upload_image")}</button>}
							<input
								ref={imageInputRef}
								hidden
								className="hidden"
								type="file"
								accept="image/*"
								onChange={handleFileInputChange}
							/>
						</div>

						<div className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left">{intl.get("social-url")}
								{!isMobile && <Tooltip message="Url of your social network" />}
							</span>
							<input
								type="text"
								value={url}
								placeholder="Url of social network"
								className="input input-bordered w-[60%]"
								onChange={(e) => setUrl(e.target.value)}
							/>
						</div>

						{/* Buttons */}
						<div className="flex gap-3 justify-center">
							{editable &&
								<div
									className={`btn ${!loading ? 'btn-secondary' : 'loading btn-disabled'}`}
									onClick={() => setLink()}
								>
									{/* {intl.get("occupy", { amount: total, tick: conversation.tick })} */}
									{intl.get("set-link")}
								</div>}
							{conversation.name &&
								<div
									className={`btn ${!loading ? 'btn-primary' : 'loading btn-disabled'}`}
									onClick={() => window.open(conversation.url)}
								>
									{intl.get("visit")}
								</div>}
						</div>

					</label>
				</label>
			</div>
		</>)
}

export default SocialBox;