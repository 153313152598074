import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { FORMAT_DECIMAL2, IMAGE_FILE_SIZE, IMAGE_PROXY, IPFS_GATEWAY, MAX_TOKEN_DESCRIPTION_LENGTH, MIN_FREEZE_TIME, ZERO_ADDRESS } from "../config";
import InscriptionFactoryV3ABI from '../abi/InscriptionFactory.json';
import { writeContract, readContract, waitForTransaction, fetchToken } from "@wagmi/core";
import { ethers } from "ethers";
import { BN, addCommas, formatDecimal, formatDecimal2, getContractAddressByVersion, parseEther } from "../utils/common";
import { DeployProps } from "../utils/types";
import Tooltip from "./Tooltip";
import intl from "../utils/intl";
import { AiOutlineOrderedList } from "react-icons/ai";
import Datepicker from "tailwind-datepicker-react"
import useDeviceDetect from "../utils/useDeviceDetect";
import InscriptionABI from '../abi/Inscription.json';
import { parseUnits } from "ethers/lib/utils";

import { NFTStorage } from 'nft.storage'


const datePickerOptions = {
	// title: "Start from",
	autoHide: true,
	todayBtn: true,
	clearBtn: true,
	maxDate: new Date("2030-01-01"),
	minDate: new Date(),
	theme: {
		background: "",
		todayBtn: "",
		clearBtn: "",
		icons: "",
		text: "m-1 px-1",
		disabledText: "m-1 px-1 bg-gray-600",
		input: "",
		inputIcon: "",
		selected: "",
	},
	icons: {
		// () => ReactElement | JSX.Element
		prev: () => <span>{"<<"}</span>,
		next: () => <span>{">>"}</span>,
	},
	datepickerClassNames: "w-12 mt-24",
	defaultDate: new Date(),
	language: "en",
}

const Deploy: FC<DeployProps> = ({
	network,
	version
}) => {
	const isMobile = useDeviceDetect();

	const [deployTick, setDeployTick] = useState("");
	const [deployName, setDeployName] = useState("");
	const [deployLogoUrl, setDeployLogoUrl] = useState("");
	const [deployCap, setDeployCap] = useState(0);
	const [deployLimitPerMint, setDeployLimitPerMint] = useState(1000);
	const [freezeTime, setFreezeTime] = useState(600);
	const [maxMintSize, setMaxMintSize] = useState(1);
	const [onlyContractAddress, setOnlyContractAddress] = useState(ZERO_ADDRESS);
	const [onlyMinQuantity, setOnlyMinQuantity] = useState(0);
	const [crowdFundingRate, setCrowdFundingRate] = useState('0');
	const [liquidityEtherPercent, setLiquidityEtherPercent] = useState("8000");
	const [liquidityTokenPercent, setLiquidityTokenPercent] = useState("4444");
	const [refundFeeRate, setRefundFeeRate] = useState("200");
	const [customizedConditonContractAddress, setCustomizedConditonContractAddress] = useState(ZERO_ADDRESS);
	const [customizedVestingContractAddress, setCustomizedVestingContractAddress] = useState(ZERO_ADDRESS);
	const [vestingStartTime, setVestingStartTime] = useState(parseInt(((new Date()).getTime() / 1000).toString()));
	const [vestingDuration, setVestingDuration] = useState(365);

	const [showRollupSize, setShowRollupSize] = useState(false);
	const [showFreezeTime, setShowFreezeTime] = useState(true);
	const [showConditionToken, setShowConditionToken] = useState(false);
	const [isWhitelist, setIsWhitelist] = useState(false);
	const [isIFOMode, setIsIFOMode] = useState(false);
	const [showIFOConfig, setShowIFOConfig] = useState(false);
	const [showCustomizeRatio, setShowCustomizeRatio] = useState(false);
	const [showCustomizedCondition, setShowCustomizedCondition] = useState(false);
	const [showCustomizedVesting, setShowCustomizedVesting] = useState(false);
	const [showStandardVesting, setShowStandardVesting] = useState(false);
	const [showDatePicker, setShowDatePicker] = useState(false);

	const [deploying, setDeploying] = useState(false);
	const imageInputRef = useRef<HTMLInputElement>(null);
	const [fileUploading, setFileUploading] = useState(false);
	const [ipfsImage, setIpfsImage] = useState("");
	const [decimals, setDecimals] = useState(0);


	const verifyInput = () => {
		if (deployTick.trim().length < network.tickLength[0] || deployTick.trim().length > network.tickLength[1]) {
			toast.error("Tick size should be " + network.tickLength[0] + " to " + network.tickLength[1]);
			return false;
		}

		if (deployCap > 5000000000000000) {
			toast.error("Cap is too large");
			return false;
		}

		if (showFreezeTime && freezeTime < MIN_FREEZE_TIME) {
			toast.error(`Freeze time can not be less than ${MIN_FREEZE_TIME} seconds`);
			return false;
		}

		if (freezeTime > 315360000) {
			toast.error("Freeze time can not be larger than 315360000 (10years)");
			return false;
		}

		// if (showRollupSize && freezeTime !== 0) {
		// 	toast.error("Freeze time should be zero if you set rollup size")
		// 	return false;
		// }

		if (deployTick.trim() === "" || deployTick.match(/[^\x00-\xff]/gm) !== null) {
			toast.error("Please input right tick");
			return false;
		};

		if (!deployLogoUrl.trim().startsWith("Qm") && !deployLogoUrl.trim().startsWith("baf")) {
			toast.error("IPFS cid is wrong");
			return false;
		}

		if (deployName.trim() === "" || deployName.trim().length > MAX_TOKEN_DESCRIPTION_LENGTH
			|| deployName.trim().match(/[^\x00-\xff]/gm) !== null) {
			toast.error("The name can not be empty, and the max length is " + MAX_TOKEN_DESCRIPTION_LENGTH);
			return false;
		};

		if (deployCap < deployLimitPerMint || deployCap <= 0 || deployLimitPerMint <= 0) {
			toast.error("Cap can not smaller than limit per mint");
			return false;
		}

		if (deployLimitPerMint <= 0) {
			toast.error("Limit per mint must biger than zero");
			return false;
		}

		if (parseInt(liquidityEtherPercent) < 0 || parseInt(liquidityEtherPercent) > 10000 || parseInt(liquidityTokenPercent) < 0 || parseInt(liquidityTokenPercent) > 5000) {
			toast.error("liquidity percent of Ether be 0-100%, and token be 0-50%");
			return false;
		}

		if (parseInt(refundFeeRate) < 0 || parseInt(refundFeeRate) > 5000) {
			toast.error("refund fee rate should be 0-50%");
			return false;
		}

		if (isIFOMode && parseFloat(crowdFundingRate) === 0) {
			toast.error("You choose FTO mode, the crowd funding rate can not be zero");
			return false;
		}

		if (!isIFOMode && parseFloat(crowdFundingRate) > 0) {
			toast.error("You dont choose FTO mode, but the crowd funding rate is not zero");
			return false;
		}

		if (isIFOMode && parseInt(liquidityEtherPercent) === 0) {
			toast.error("You choose FTO mode, the liquidity percent of Ether can not be zero");
			return false;
		}

		if (isIFOMode && parseInt(liquidityTokenPercent) === 0) {
			toast.error("You choose FTO mode, the liquidity percent of Token can not be zero");
		}

		if (isIFOMode && (parseFloat(crowdFundingRate) < 0.00001)) {
			toast.error("crowd funding rate should more than 0.00001");
			return false;
		}

		if (isIFOMode && verifyCDET().result) {
			// Check the smart contract: PriceFormat.sol to avoid underflow/overflow of getInitialRate()
			toast.error(`Limit per mint must smaller than ${addCommas(verifyCDET().max.toString())}`);
			return false;
		}
		return true;
	}

	const verifyCDET = () => {
		const a = ethers.utils.parseUnits(crowdFundingRate, 14);
		const b = parseInt(liquidityEtherPercent);
		const c = parseInt(liquidityTokenPercent);
		const d = deployLimitPerMint;
		const aa = a.mul(b).mul(10000 - c).div(c);
		if (aa.lte(d)) return { result: true, max: aa };
		else return { result: false, max: aa };
	}

	const deploy = async () => {
		if (!verifyInput()) return;

		try {
			setDeploying(true);

			const contractAddress = network.contractAddress;

			// Deploying
			const args = [
				deployName,
				deployTick,
				deployLogoUrl,
				parseEther(deployCap.toString()), // cap
				parseEther(deployLimitPerMint.toString()), // limitPerMint
				maxMintSize.toString(),
				showFreezeTime ? freezeTime.toString() : "0",
				onlyContractAddress,
				decimals > 0 ? parseUnits(onlyMinQuantity.toString(), decimals) : BN(onlyMinQuantity), // onlyMintQuantity
				parseEther(crowdFundingRate === "" ? "0" : crowdFundingRate),
				isWhitelist,
				isIFOMode,
				isIFOMode ? liquidityTokenPercent : "0",
				isIFOMode ? liquidityEtherPercent : "0",
				refundFeeRate,
				customizedConditonContractAddress,
				showStandardVesting,
				vestingStartTime, // production
				// Math.round((new Date()).getTime() / 1000), // ###### development
				vestingDuration * 3600 * 24,
				customizedVestingContractAddress
			];

			// console.log(args)
			const { hash } = await writeContract({
				address: contractAddress as any,
				abi: InscriptionFactoryV3ABI,
				functionName: 'deploy',
				args,
			});

			waitForTransaction({ hash }).then(async (data) => {
				if (data.status === 'success') {
					toast.success("Deploy successfully");
				} else {
					toast.error("Deploy Error");
				}
				setDeploying(false);
			})
		} catch (err) {
			setDeploying(false);
			toast.error("Error: " + (err as any).shortMessage);
		}
	}

	const uploadFile = async (file: File) => {
		try {
			setFileUploading(true);

			const storage = new NFTStorage({ token: process.env.REACT_APP_NFT_STORAGE_KEY as string })

			const cid = await storage.store({
				name: 'Ferc20',
				description: 'Ferc20',
				image: file
			})
			if (cid && cid.data.image.href) {
				const regex = /ipfs:\/\/(.*?)\//
				const result = regex.exec(cid.data.image.href)

				if (result) setDeployLogoUrl(result[1])
				else toast.error("upload image fail")
			} else {
				toast.error("upload image fail")
			}

			setFileUploading(false);

		} catch (error) {
			console.log(error);
			setFileUploading(false);
		}
	};



	const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const file: File | undefined = e.target.files?.[0];
		if (!file) return;

		uploadFile(file);
	};

	const maxRollups = () => {
		// Math.floor(deployCap * (1 - liquidityTokenPercent) / limitPerMint)
		if (deployLimitPerMint === 0) return 0;
		return Math.floor(deployCap * (10000 - parseInt(liquidityTokenPercent)) / 10000 / deployLimitPerMint);
	}

	const actualCap = () => {
		// maxRollup * limitPerMint / (1 - liquidityTokenPercent) 
		const _liquidityTokenPercent = parseInt(liquidityTokenPercent);
		if (_liquidityTokenPercent === 10000) return 0;
		else return maxRollups() * deployLimitPerMint * 10000 / (10000 - _liquidityTokenPercent);
	}

	const mintableForMiner = () => {
		// acturalCap * (1 - liquidityTokenPercent)
		return actualCap() * (10000 - parseInt(liquidityTokenPercent)) / 10000
	}

	const liquidityTokens = () => {
		// acturalCap - mintableForMiner || actualCap * liquidityTokenPercent
		return actualCap() * parseInt(liquidityTokenPercent) / 10000;
	}

	const liquidityEthers = () => {
		// maxRollup * crowdFundingRate * (1 - commissionRate) * (1 - liquidityEtherPercent)

		return maxRollups() * parseFloat(crowdFundingRate) * (10000 - network.fundingCommissions) * parseInt(liquidityEtherPercent) / 100000000;
	}

	const initialRate = () => {
		// liquidityEthers / liquidityTokens
		const _liquidityTokens = liquidityTokens();
		if (_liquidityTokens === 0) return 0;
		else return liquidityEthers() / _liquidityTokens;
	}

	const premiumRate = () => {
		// initialRate / crowdFundingRate * limitPerMint
		const _crowdFundingRate = parseFloat(crowdFundingRate);
		if (_crowdFundingRate === 0) return 0;
		else return initialRate() * deployLimitPerMint / _crowdFundingRate;
	}

	const handleDatePickerChange = (selectedDate: Date) => {
		const timeStamp = (new Date(selectedDate)).getTime() / 1000;
		setVestingStartTime(parseInt(timeStamp.toString()));
	}

	const handleDatePickerClose = (state: boolean) => {
		setShowDatePicker(state);
	}

	const changeOnlyContractAddress = async (e: ChangeEvent<HTMLInputElement>) => {
		setOnlyContractAddress(e.target.value === '' ? ZERO_ADDRESS : e.target.value)

		if (e.target.value !== ZERO_ADDRESS) {
			setDecimals(0)
			try {
				const tokenInfo: any = await fetchToken({
					address: e.target.value as any
				});
				if (tokenInfo.decimals > 0) {
					setDecimals(tokenInfo.decimals)
				} else {
					setDecimals(0)
				}
			} catch (e: any) {
				if (e.name === 'ContractFunctionExecutionError') {
					setDecimals(0)
				}
			}
		}
	}


	useEffect(() => {
		const g = (async (cid: string) => {
			try {
				const res = await window.w3sClient.get(cid); // Web3Response
				const files = await res?.files(); // Web3File[]

				if (files && files.length > 0) {
					const file = files[0];
					setIpfsImage(`https://${cid}${IPFS_GATEWAY}/${file.name}`)
				} else {
					setIpfsImage(`https://${cid}${IPFS_GATEWAY}`)
				}
			} catch (err) {

			}
		})
		if (deployLogoUrl) {
			g(deployLogoUrl)
		}

	}, [deployLogoUrl])

	return (
		<>
			<div>
				<input type="checkbox" id="deployModal" className="modal-toggle" />
				<label htmlFor="deployModal" className="modal cursor-pointer">
					<label className="modal-box w-12/12 max-w-3xl text-sm md:text-md" htmlFor="">
						<label htmlFor="deployModal" className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</label>
						<h3 className="font-bold text-lg">{intl.get("deploy")}</h3>
						<div className="divider" />

						<label className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left">🌞&nbsp;{intl.get("tick")}
								{!isMobile &&
									<Tooltip
										message={intl.get("tooltip-tick")}
									/>}
							</span>
							<input type="text" value={deployTick} placeholder={`${network.tickLength && network.tickLength[0]}-${network.tickLength && network.tickLength[1]} characters like 'abcd'...`} className="input input-bordered w-[60%]" onChange={(e) => setDeployTick(e.target.value)} />
						</label>

						<label className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left">🌟&nbsp;{intl.get("name")}
								{!isMobile &&
									<Tooltip
										message={intl.get("tooltip-name")}
									/>}
							</span>
							<input type="text" value={deployName} placeholder="Name of token(max 16 chars)" className="input input-bordered w-[60%]" onChange={(e) => setDeployName(e.target.value)} />
						</label>

						<label className="input-group flex justify-center mb-3">
							<span className="min-w-[40%] text-left">🔥&nbsp;{intl.get("logo-url")}
								{!isMobile &&
									<Tooltip
										message={intl.get("tooltip-logo-url")}
									/>}
							</span>
							<input
								type="text"
								value={deployLogoUrl}
								placeholder="Token logo IPFS cid"
								className="input input-bordered w-full"
								onChange={(e) => setDeployLogoUrl(e.target.value)}
							/>
							{fileUploading ? <button className="btn btn-info btn-disabled loading"></button> : deployLogoUrl ?
								<div className="avatar rounded-r-full ml-1">
									<div className="w-12 h-12 rounded">
										<img src={IMAGE_PROXY(ipfsImage)} alt="" crossOrigin="anonymous" onClick={() => { imageInputRef.current?.click() }} />
									</div>
								</div>
								: <button className="btn btn-primary rounded-r-md" onClick={() => { imageInputRef.current?.click() }}>{intl.get("upload_image")}</button>}
							<input
								ref={imageInputRef}
								hidden
								className="hidden"
								type="file"
								accept="image/*"
								onChange={handleFileInputChange}
							/>
						</label>

						<label className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left">🌈&nbsp;{intl.get("hard-cap")}</span>
							<input type="text" value={deployCap} placeholder="21000000" className="input input-bordered w-[60%]" onChange={(e) => setDeployCap(parseInt(e.target.value === '' ? '0' : e.target.value))} />
						</label>

						<label className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left">⚡️&nbsp;{intl.get("limit-per-mint")}
								{!isMobile &&
									<Tooltip
										message={intl.get("tooltip-limit-per-mint")}
									/>}
							</span>
							<input type="text" value={deployLimitPerMint} placeholder="1000" className="input input-bordered w-[60%]" onChange={(e) => setDeployLimitPerMint(parseInt(e.target.value === '' ? '0' : e.target.value))} />
						</label>

						<div className="divider max-w-xs m-auto mb-2" />
						{/* Freeze time/batch mint or not */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left font-bold">
								🦜&nbsp;{intl.get("freeze-time-selector")}
							</span>
							<select
								className="select select-bordered w-[60%]"
								onChange={(e) => {
									setShowRollupSize(e.currentTarget.value === "1");
									setShowFreezeTime(e.currentTarget.value !== "1");
									if (e.currentTarget.value === "0") setMaxMintSize(1);
									if (e.currentTarget.value === "1") setFreezeTime(0);
								}}
								defaultValue="0"
							>
								{/* <option disabled selected>{intl.get("select-freeze-time")}</option> */}
								<option value="0">👉🏻&nbsp;{intl.get("has-freeze-time")}</option>
								<option value="1">👉🏻&nbsp;{intl.get("no-freeze-time")}</option>
							</select>
						</div>

						{/* Rollup size */}
						{showRollupSize &&
							<label className="input-group flex justify-center mb-3">
								<span className="w-[40%] text-left">👉🏻&nbsp;{intl.get("rollup-size")}
									{!isMobile &&
										<Tooltip
											message={intl.get("tooltip-rollup-size")}
										/>}
								</span>
								<input type="text" value={maxMintSize} placeholder="1" className="input input-bordered w-[60%]" onChange={(e) => setMaxMintSize(parseInt(e.target.value === '' ? '0' : e.target.value))} />
							</label>}

						{/* Set freeze time */}
						{showFreezeTime &&
							<label className="input-group flex justify-center mb-3">
								<span className="w-[40%] text-left">👉🏻&nbsp;{intl.get("frozen-time")}
									{!isMobile &&
										<Tooltip
											message={`${intl.get("tooltip-frozen-time")} ${network.symbol} ${intl.get("tooltip-frozen-time2")}`}
										/>}
								</span>
								<input type="text" value={freezeTime} placeholder="0" className="input input-bordered w-[60%]" onChange={(e) => setFreezeTime(parseInt(e.target.value === '' ? '0' : e.target.value))} />
							</label>}

						<div className="divider max-w-xs m-auto mb-2" />

						{/* Mint condition */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left font-bold">
								🐻&nbsp;{intl.get("condition-selector")}
							</span>
							<select
								className="select select-bordered w-[60%]"
								onChange={(e) => {
									setShowConditionToken(e.currentTarget.value === "2");
									setIsWhitelist(e.currentTarget.value === "1");
									setShowCustomizedCondition(e.currentTarget.value === "3");
									if (e.currentTarget.value === "0") {
										setOnlyContractAddress(ZERO_ADDRESS);
										setOnlyMinQuantity(0);
										setIsWhitelist(false);
									}
								}}
								defaultValue="0"
							>
								<option value="0">👉🏻&nbsp;{intl.get("condition-no")}</option>
								<option value="1">👉🏻&nbsp;{intl.get("condition-whitelist")}</option>
								<option value="2">👉🏻&nbsp;{intl.get("condition-erc20-nft")}</option>
								<option value="3">👉🏻&nbsp;{intl.get("condition-customize")}</option>
							</select>
						</div>

						{showConditionToken &&
							<div>
								<label className="input-group flex justify-center mb-3">
									<span className="w-[40%] text-left">👉🏻&nbsp;{intl.get("erc20-nft")}
										{!isMobile &&
											<Tooltip
												message={intl.get("tooltip-erc20-nft")}
											/>}
									</span>
									<input
										type="text"
										value={onlyContractAddress}
										placeholder={ZERO_ADDRESS}
										className="input input-bordered w-[60%]"
										onClick={(e) => e.currentTarget.select()}
										onChange={(e) => changeOnlyContractAddress(e)}
									/>
								</label>

								<label className="input-group flex justify-center mb-3">
									<span className="w-[40%] text-left">👉🏻&nbsp;{intl.get("min-hold-amount")}
										{!isMobile &&
											<Tooltip
												message={intl.get("tooltip-min-hold-amount")}
											/>}
									</span>
									<input
										type="text"
										value={onlyMinQuantity}
										placeholder={"0"}
										className="input input-bordered w-[60%]"
										onChange={(e) => setOnlyMinQuantity(parseFloat(e.target.value === '' ? '0' : e.target.value))}
									/>
								</label>
							</div>}

						{isWhitelist && <>
							<label className="flex justify-center mb-3">
								<span className="w-[40%] text-left"></span>
								<span className="w-[60%] text-left ml-4 flex">
									<AiOutlineOrderedList className="mt-1 mr-2" />
									<a href="/whitelist" target="_blank" rel="noreferrer">{intl.get("set-whitelist")}</a>
								</span>
							</label>
						</>}

						{showCustomizedCondition &&
							<div>
								<label className="input-group flex justify-center mb-3">
									<span className="w-[40%] text-left">{intl.get("condition-customize")}
										{!isMobile &&
											<Tooltip
												message={intl.get("tooltip-condition-customize")}
											/>}
									</span>
									<input
										type="text"
										value={customizedConditonContractAddress}
										placeholder={ZERO_ADDRESS}
										className="input input-bordered w-[60%]"
										onClick={(e) => e.currentTarget.select()}
										onChange={(e) => setCustomizedConditonContractAddress(e.target.value === '' ? ZERO_ADDRESS : e.target.value)}
									/>
								</label>
							</div>}

						<div className="divider max-w-xs m-auto mb-2" />

						{/* Token allication */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left font-bold">
								🐶&nbsp;{intl.get("token-allocation")}
							</span>
							<select
								className="select select-bordered w-[60%]"
								onChange={(e) => {
									if (e.currentTarget.value === "2") {
										setShowCustomizedVesting(true);
										setShowStandardVesting(false);
									} else if (e.currentTarget.value === "1") {
										setShowStandardVesting(true);
										setShowCustomizedVesting(false);
									} else {
										setShowCustomizedVesting(false);
										setShowStandardVesting(false);
										setCustomizedVestingContractAddress(ZERO_ADDRESS);
									}
								}}
								defaultValue="0"
							>
								<option value="0">👉🏻&nbsp;{intl.get("token-allocation-no-vesting")}</option>
								<option value="1">👉🏻&nbsp;{intl.get("token-allocation-standard-vesting")}</option>
								<option value="2">👉🏻&nbsp;{intl.get("token-allocation-vesting")}</option>
							</select>
						</div>

						{showCustomizedVesting &&
							<>
								<label className="input-group flex justify-center mb-3">
									<span className="w-[40%] text-left">👉🏻&nbsp;{intl.get("customized-vesting-contract")}
										{!isMobile &&
											<Tooltip
												message={intl.get("tooltip-customized-vesting-contract")}
											/>}
									</span>
									<input
										type="text"
										value={customizedVestingContractAddress}
										placeholder={ZERO_ADDRESS} className="input input-bordered w-[60%]"
										onClick={(e) => e.currentTarget.select()}
										onChange={(e) => setCustomizedVestingContractAddress(e.target.value === '' ? ZERO_ADDRESS : e.target.value)}
									/>
								</label>
							</>}

						{showStandardVesting &&
							<div>
								<label className="input-group flex justify-center mb-3">
									<span className="w-[40%] text-left">👉🏻&nbsp;{intl.get("vesting-start-from")}</span>
									<span className="input w-[60%] bg-base-100">
										<Datepicker
											options={datePickerOptions}
											onChange={handleDatePickerChange}
											show={showDatePicker}
											setShow={handleDatePickerClose}
										/>
									</span>
								</label>

								<label className="input-group flex justify-center mb-3">
									<span className="w-[40%] text-left">👉🏻&nbsp;{intl.get("vesting-duration")}
									</span>
									<input
										type="text"
										value={vestingDuration}
										placeholder={"0"}
										className="input input-bordered w-[60%]"
										onChange={(e) => setVestingDuration(parseInt(e.target.value === '' ? '0' : e.target.value))}
									/>
								</label>
							</div>}

						<div className="divider max-w-xs m-auto mb-2" />

						{/* Model selector */}
						<div className="input-group flex justify-center mb-3">
							<span className="w-[40%] text-left font-bold">
								🦊&nbsp;{intl.get("model-selector")}
							</span>
							<select
								className="select select-bordered w-[60%]"
								onChange={(e) => {
									setShowIFOConfig(e.currentTarget.value === "1");
									setIsIFOMode(e.currentTarget.value === "1");
									if (e.currentTarget.value === "0") {
										setCrowdFundingRate("0");
										setLiquidityTokenPercent("0");
										setLiquidityEtherPercent("0");
									}
								}}
								defaultValue="0"
							>
								<option value="0">👉🏻&nbsp;{intl.get("model-free-launch")}</option>
								<option value="1">👉🏻&nbsp;{intl.get("model-ifo")}</option>
							</select>
						</div>


						{showIFOConfig &&
							<div>

								<div className="input-group flex justify-center mb-3">
									<span className="w-[40%] text-left">👉🏻&nbsp;{intl.get("ifo-ratio-selector")}
										{!isMobile &&
											<Tooltip
												message={intl.get("tooltip-ifo-ratio-selector")}
											/>}
									</span>
									<select
										className="select select-bordered w-[60%]"
										onChange={(e) => {
											if (e.currentTarget.value === "0") {
												setShowCustomizeRatio(true);
											} else {
												const percent = e.currentTarget.value.split("-");
												setLiquidityEtherPercent(percent[0]);
												setLiquidityTokenPercent(percent[1]);
												setShowCustomizeRatio(false);
											}
										}}
										defaultValue="8000-4444"
									>
										<option value="10000-5000">👉🏻&nbsp;{intl.get("ifo-ratio", { etherPercent: 100, tokenPercent: 50 })}</option>
										<option value="9000-4737">👉🏻&nbsp;{intl.get("ifo-ratio", { etherPercent: 90, tokenPercent: 47.37 })}</option>
										<option value="8000-4444">👉🏻&nbsp;{intl.get("ifo-ratio", { etherPercent: 80, tokenPercent: 44.44 })}</option>
										<option value="7000-4118">👉🏻&nbsp;{intl.get("ifo-ratio", { etherPercent: 70, tokenPercent: 41.18 })}</option>
										<option value="6000-3750">👉🏻&nbsp;{intl.get("ifo-ratio", { etherPercent: 60, tokenPercent: 37.50 })}</option>
										<option value="5000-3333">👉🏻&nbsp;{intl.get("ifo-ratio", { etherPercent: 50, tokenPercent: 33.33 })}</option>
										<option value="4000-2857">👉🏻&nbsp;{intl.get("ifo-ratio", { etherPercent: 40, tokenPercent: 28.57 })}</option>
										<option value="3000-2308">👉🏻&nbsp;{intl.get("ifo-ratio", { etherPercent: 30, tokenPercent: 23.08 })}</option>
										<option value="2000-1667">👉🏻&nbsp;{intl.get("ifo-ratio", { etherPercent: 20, tokenPercent: 16.67 })}</option>
										<option value="1000-909">👉🏻&nbsp;{intl.get("ifo-ratio", { etherPercent: 10, tokenPercent: 9.09 })}</option>
										<option value="0">👉🏻&nbsp;{intl.get("ifo-ratio-custom")}</option>
									</select>
								</div>

								{showCustomizeRatio && <>
									<label className="input-group flex justify-center mb-3">
										<span className="w-[40%] text-left">👉🏻&nbsp;{intl.get("ifo-ratio-customize-ether-percent", { symbol: network.symbol })}
										</span>
										<input type="text" value={(parseInt(liquidityEtherPercent) / 100).toFixed(2)} placeholder={"0"} className="input input-bordered w-[60%]" onChange={(e) => setLiquidityEtherPercent((parseFloat(e.target.value) * 100).toString())} />
									</label>
									<label className="input-group flex justify-center mb-3">
										<span className="w-[40%] text-left">👉🏻&nbsp;{intl.get("ifo-ratio-customize-token-percent", { tick: deployTick })}
										</span>
										<input type="text" value={(parseInt(liquidityTokenPercent) / 100).toFixed(2)} placeholder={"0"} className="input input-bordered w-[60%]" onChange={(e) => setLiquidityTokenPercent((parseFloat(e.target.value) * 100).toString())} />
									</label>
								</>}

								<label className="input-group flex justify-center mb-3">
									<span className="w-[40%] text-left">👉🏻&nbsp;{intl.get("funding-rate")}
									</span>
									<input type="text" value={crowdFundingRate} placeholder={"0"} className="input input-bordered w-[60%]" onChange={(e) => setCrowdFundingRate(e.target.value === '' ? '0' : e.target.value)} />
								</label>

								<label className="input-group flex justify-center mb-3">
									<span className="w-[40%] text-left">👉🏻&nbsp;{intl.get("refund-fee-rate")}
									</span>
									<select
										className="select select-bordered w-[60%]"
										onChange={(e) => {
											setRefundFeeRate(e.currentTarget.value);
										}}
										defaultValue="200"
									>
										<option value="0">👉🏻&nbsp;{"0%"}</option>
										<option value="100">👉🏻&nbsp;{"1%"}</option>
										<option value="200">👉🏻&nbsp;{"2%"}</option>
										<option value="300">👉🏻&nbsp;{"3%"}</option>
										<option value="400">👉🏻&nbsp;{"4%"}</option>
										<option value="500">👉🏻&nbsp;{"5%"}</option>
										<option value="600">👉🏻&nbsp;{"6%"}</option>
										<option value="700">👉🏻&nbsp;{"7%"}</option>
										<option value="800">👉🏻&nbsp;{"8%"}</option>
										<option value="900">👉🏻&nbsp;{"9%"}</option>
										<option value="1000">👉🏻&nbsp;{"10%"}</option>
									</select>
								</label>

								{/* FTO model price and other information automaticly show */}
								<div className="h-2"></div>

								<label className="flex justify-center mb-3">
									<span className="w-[40%] text-left ml-4">🍏&nbsp;{intl.get("max-rollups")}
										{!isMobile && <Tooltip message={"maxRollups = Cap * (1 - liquidityTokenPercent) / limitPerMint"} />}
									</span>
									<span className="w-[60%] ml-4 text-right font-bold text-secondary">{maxRollups()}</span>
								</label>

								<label className="flex justify-center mb-3">
									<span className="w-[40%] text-left ml-4">🍓&nbsp;{intl.get("actural-cap")}
										{!isMobile && <Tooltip message={"acturalCap = maxRollup * limitPerMint / (1 - liquidityTokenPercent)"} />}
									</span>
									<span className="w-[60%] ml-4 text-right font-bold text-secondary">{formatDecimal2(actualCap(), FORMAT_DECIMAL2)}</span>
								</label>

								<label className="flex justify-center mb-3">
									<span className="w-[40%] text-left ml-4">🍉&nbsp;{intl.get("total-tokens-for-miner")}
										{!isMobile && <Tooltip message={"mintableForMiner = acturalCap * (1 - liquidityTokenPercent)"} />}
									</span>
									<span className="w-[60%] ml-4 text-right font-bold text-secondary">{formatDecimal2(mintableForMiner(), FORMAT_DECIMAL2)}</span>
								</label>

								<label className="flex justify-center mb-3">
									<span className="w-[40%] text-left ml-4">🍌&nbsp;{intl.get("total-tokens-in-lp")}
										{!isMobile && <Tooltip message={"liquidityTokens = actualCap * liquidityTokenPercent"} />}
									</span>
									<span className="w-[60%] ml-4 text-right font-bold text-secondary">{formatDecimal2(liquidityTokens(), FORMAT_DECIMAL2)}</span>
								</label>

								<label className="flex justify-center mb-3">
									<span className="w-[40%] text-left ml-4">🍠&nbsp;{intl.get("total-ethers-in-lp", { symbol: network.symbol })}
										{!isMobile && <Tooltip message={"liquidityEthers = maxRollup * crowdFundingRate * (1 - commissionRate) * (1 - liquidityEtherPercent)"} />}
									</span>
									<span className="w-[60%] ml-4 text-right font-bold text-secondary">{formatDecimal2(liquidityEthers(), FORMAT_DECIMAL2) + " " + network.symbol}</span>
								</label>

								<label className="flex justify-center mb-3">
									<span className="w-[40%] text-left ml-4">🍑&nbsp;{intl.get("estimated-initial-price")}
										{!isMobile && <Tooltip message={"initialRate = liquidityEthers / liquidityTokens"} />}
									</span>
									<span className="w-[60%] ml-4 text-right font-bold text-secondary">{formatDecimal2(initialRate(), FORMAT_DECIMAL2) + " " + network.symbol}</span>
								</label>

								<label className="flex justify-center mb-3">
									<span className="w-[40%] text-left ml-4">🍒&nbsp;{intl.get("estimated-premium-race")}
										{!isMobile && <Tooltip message={"premiumRate = initialRate / crowdFundingRate * limitPerMint"} />}
									</span>
									<span className="w-[60%] ml-4 text-right font-bold text-secondary">{formatDecimal2(premiumRate(), 2)}</span>
								</label>

							</div>}

						<div className="modal-action">
							<button className={`btn ${deploying ? 'loading btn-disabled' : 'btn-primary'}`} onClick={() => deploy()}>{intl.get("deploy")}</button>
						</div>
					</label>
				</label>
			</div>
		</>)
}

export default Deploy;